<template>
  <LastBetsSportTable
    :bets="lastSportBets as any"
    :is-loading="pending"
    :mobile="props.mobile"
  />
</template>

<script setup lang="ts">
import { useLastBetsSport } from '../../../../composables'
import LastBetsSportTable from '../tables/LastBetsSportTable.vue'

const props = withDefaults(
  defineProps<{
    mobile?: boolean
  }>(),
  {
    mobile: false,
  },
)
const { lastSportBets, pending } = useLastBetsSport(props.mobile ? 8 : 10)
</script>
