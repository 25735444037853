import type { SportLastBet } from '@st/casino/components/LastBets/types'

export function useLastBigBetsSport(itemsNumber = 9) {
  const io = useSocket()

  interface UseLastBigBetsSport {
    bigBetDetails: SportLastBet
  }

  const { data: sportBigBets, pending } = useStFetch('/bet/last-big-bets', {
    method: 'post',
    transform: (response) =>
      response.slice(0, itemsNumber) as unknown as SportLastBet[],
  })
  function sportBigBetDetailsListener(val: UseLastBigBetsSport) {
    if (sportBigBets.value) {
      sportBigBets.value.unshift(val.bigBetDetails)
      sportBigBets.value.splice(itemsNumber)
    }
  }

  onMounted(() => {
    io.on('lastBetsBigBetDetails', sportBigBetDetailsListener)
  })

  onBeforeUnmount(() => {
    io.off('lastBetsBigBetDetails', sportBigBetDetailsListener)
  })

  return { lastSportBigBets: sportBigBets, pending }
}
