import type { SportLastBet } from '@st/casino/components/LastBets/types'

export function useLastBetsSport(itemsNumber = 9) {
  const io = useSocket()

  interface UseLastBetsSport {
    betDetails: SportLastBet
  }

  const { data: sportBets, pending } = useStFetch('/bet/last-bets', {
    method: 'post',
    transform: (response) =>
      response.slice(0, itemsNumber) as unknown as SportLastBet[],
  })
  function sportBetDetailsListener(val: UseLastBetsSport) {
    if (sportBets.value) {
      sportBets.value.unshift(val.betDetails)
      sportBets.value.splice(itemsNumber)
    }
  }

  onMounted(() => {
    io.on('lastBetsBetDetails', sportBetDetailsListener)
  })

  onBeforeUnmount(() => {
    io.off('lastBetsBetDetails', sportBetDetailsListener)
  })

  return { lastSportBets: sportBets, pending }
}
