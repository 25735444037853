<template>
  <LastBetsCasinoTable
    :bets="lastBets"
    :is-loading="pending"
    :mobile="props.mobile"
  />
</template>

<script setup lang="ts">
import { useLastBetsCasino } from '../../../../composables'
import LastBetsCasinoTable from '../tables/LastBetsCasinoTable.vue'

const props = withDefaults(
  defineProps<{
    mobile?: boolean
  }>(),
  {
    mobile: false,
  },
)
const { lastBets, pending } = useLastBetsCasino(props.mobile ? 8 : 10)
</script>
